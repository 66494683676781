@import "vars";

@media all and (min-width: 992px) {
  .container {
    min-height: 100vh;
  }
  .job-screen {
    padding: 0;
    &__logo {
      margin-bottom: 40px;
    }
    &:before {
      width: 31.25%;
    }
    &.revealed {
      padding-top: 0;
    }
    h2 {
      font-size: 20px;
    }
    .job-screen__title {
      margin: 0 0 30px;
    }
    &__background {
      display: none;
    }
    &__description {
      h2 {
        margin-bottom: 20px;
      }
    }
    .container {
      max-width: 100%;
    }
    .content {
      display: flex;
      margin-left: 31.25%;
    }
    &__title {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 31.25%;
      height: 100vh;
      padding: 15px 3px 35px;
      margin: 0;
      transition: none;
      left: 0;
      top: 0;
    }
    &__content {
      padding: 80px 100px 40px;
      color: #202020;
      max-width: 100%;
    }
    &__benefits {
      margin-bottom: 90px;
    }
    &__apply-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 0 10px;
        &:last-child {
          flex: 0 0 100%;
        }
      }
    }
  }
  .form-screen {
    padding: 0;
    .popup-cv {
      width: 68.75%;
    }
    &__button {
      width: 330px;
    }
    &__progress {
      margin-bottom: 40px;
    }
    .container {
      display: flex;
      max-width: 100%;
    }
    &__form-section {
      background: #F9F9F9;
      padding: 40px 60px;
      max-width: initial;
      width: 68.75%;
      margin-left: 31.25%;
      .fill-in {
        padding: 30px 20px;
        h2 {
          text-align: center;
        }
        .form-group {
          max-width: 330px;
          margin: 0 auto 28px;
        }
      }
      .change-photo {
        .form-screen__change {
          width: calc(100% - 80px);
        }
      }
      .second-screen {
        .form-screen__button {
          margin-top: 40px;
        }
      }
      .textarea {
        textarea {
          margin-bottom: 10px;
        }
      }
      .screen.first-screen .fill-in .form-screen__block {
        max-width: 330px;
      }
    }
    &__block {
      margin-bottom: 15px;
      &.change-photo {
        padding: 20px 30px;
        cursor: pointer;
      }
      &.apply-via {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 40px;
      }
    }
    &__blocks-group {
      display: flex;
      justify-content: space-between;
      .form-screen__block {
        width: calc(50% - 8px);
      }
    }
  }
  .applied-screen {
    padding: 25px 15px 47px;
    .container {
      max-width: 520px;
      min-height: initial;
    }
    .job-screen__title {
      min-height: initial;
      height: auto;
      width: auto;
      padding-bottom: 30px;
      position: relative;
    }
  }
  .profile {
    &__improve {
      &-wrapper {
        padding: 55px 115px 15px;
      }
    }

  }
  .popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 68.75%;
    right: 0;
    left: unset;
    &-terms {
      .popup__content {
        width: 100%;
      }
      &__text {
        width: 520px;
      }
    }
    &__content {
      max-width: 100%;
      width: 652px;
    }
    &__title {
      margin-bottom: 30px;
    }
    &__upload {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-socials {
        display: none;
      }
      div {
        width: calc(50% - 5px);
        > .popup__upload-item {
          width: 100%;
        }
      }
      .popup__upload-item {
        width: calc(50% - 5px);
        &.social {
          position: relative;
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
    label {
      &.popup__no-photo {
        margin-top: 40px;
        padding: 16px 20px;
      }
    }
    &__variants {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
    &__profile {
      width: calc(50% - 5px);
    }
    &__fb {
      justify-content: flex-start;
      margin: 0 -5px;
      label {
        width: 145px;
        margin: 10px 5px 0;
      }
    }
    &-cv {
      width: 100%;
      justify-content: flex-start;
      padding-top: 100px;
      .popup__content {
        max-width: 340px;
        padding: 40px 20px;
      }
      .popup__upload {
        > div, .popup__upload-item {
          width: 100%;
        }
      }
    }
    &-confirmation {
      width: 100%;
      justify-content: flex-start;
      padding-top: 100px;
      text-align: left;

      span {
        font-size: 15px;
        display: flex;
      }
    }
  }
  .error-block {
    width: 68.75%;
    margin-left: 31.25%;
    padding: 20px 60px;
    &.thanks,
    &.full-width {
      width: 100%;
      margin-left: 0;
    }
  }
}
@media all and (max-width: 991px) {
  .job-screen{
    .job-screen__title {
      background: none!important;
    }
  }
  .form-screen {
    .job-screen__logo {
      display: none;
    }
  }
}
