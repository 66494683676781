/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a {
  text-decoration: none; }

a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

@font-face {
  font-family: "montserrat";
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/Montserrat-Black.ded5470b.eot);
  src: url(/static/media/Montserrat-Black.ded5470b.eot?#iefix) format("embedded-opentype"), url(/static/media/Montserrat-Black.e916e918.woff) format("woff"), url(/static/media/Montserrat-Black.e7161033.ttf) format("truetype"); }

@font-face {
  font-family: "proxima";
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/ProximaNova-Regular.b7c51278.eot);
  src: url(/static/media/ProximaNova-Regular.b7c51278.eot?#iefix) format("embedded-opentype"), url(/static/media/ProximaNova-Regular.2d2ae255.woff) format("woff"), url(/static/media/ProximaNova-Regular.7ce6760d.ttf) format("truetype"); }

@font-face {
  font-family: "proxima-bold";
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/ProximaNova-Bold.10141a9d.eot);
  src: url(/static/media/ProximaNova-Bold.10141a9d.eot?#iefix) format("embedded-opentype"), url(/static/media/ProximaNova-Bold.e2cf3dc2.woff) format("woff"), url(/static/media/ProximaNova-Bold.926a08fb.ttf) format("truetype"); }

@font-face {
  font-family: "proxima-semib";
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/ProximaNova-Semibold.cddac658.eot);
  src: url(/static/media/ProximaNova-Semibold.cddac658.eot?#iefix) format("embedded-opentype"), url(/static/media/ProximaNova-Semibold.8feb512e.woff) format("woff"), url(/static/media/ProximaNova-Semibold.df8c6264.ttf) format("truetype"); }

html.fixed {
  max-height: 100%;
  overflow: hidden; }

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: "proxima", sans-serif;
  overflow-x: hidden;
  color: #fff; }

.overflow-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden; }

input:-webkit-autofill {
  -webkit-text-fill-color: #9b9b9b;
  -webkit-box-shadow: inset 0 0 0 30px #fff; }

button {
  background: none;
  border: none;
  width: 280px;
  text-align: center;
  font-size: 18px;
  font-family: "proxima-semib";
  color: #fff;
  padding: 15px 0;
  border-radius: 5px;
  margin-bottom: 12px;
  max-width: 100%; }
  button:hover {
    opacity: 0.85; }
  button.alt {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    color: #9B9B9B;
    font-size: 16px;
    margin-top: 20px;
    font-family: "proxima-bold"; }
    button.alt::after {
      content: '';
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 1px;
      width: 11px;
      height: 10px;
      background: url(/static/media/arrow.456705e5.svg) no-repeat center center/contain; }

input, textarea {
  -webkit-appearance: none;
  border-radius: 0; }

form label {
  position: relative; }

input[type="date"]:before {
  content: attr(placeholder);
  color: #9b9b9b;
  margin-right: 0.5em; }

input[type=file],
input[name=avatar],
.hidden {
  display: none !important; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.checkbox, .radio {
  position: absolute;
  top: 5px;
  opacity: -1;
  z-index: 10;
  width: 14px;
  height: 14px;
  cursor: pointer;
  outline: none; }

.checkboxmark, .radiomark {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #979797;
  background: transparent;
  top: 5px;
  left: 0;
  border-radius: 3px;
  position: absolute;
  margin-right: 6px;
  -webkit-transition: .2s ease-in;
  transition: .2s ease-in; }

.radiomark {
  border-radius: 50%; }

.checkbox:checked ~ .checkboxmark::after,
.radio:checked ~ .radiomark::after {
  display: block;
  content: ''; }

.checkbox:checked ~ .checkboxmark,
.radio:checked ~ .radiomark {
  border-color: #51C380;
  background: #51C380;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out; }

.checkboxmark::after,
.radiomark::after {
  position: absolute;
  left: 4px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.radiomark::after {
  width: 5px;
  height: 5px;
  top: 3px;
  left: 3px;
  background: #fff;
  border-radius: 50%; }

.container {
  max-width: 420px;
  margin: 0 auto;
  position: relative; }

.job-screen {
  text-align: center;
  padding: 30px 35px;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out; }
  .job-screen__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #009FDF;
    padding: 10px;
    margin: 0 auto 20px; }
    .job-screen__logo img {
      max-width: 100%; }
  .job-screen.revealed button.alt:after {
    display: none; }
  .job-screen.revealed button.alt::before {
    content: '';
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: 1px;
    width: 11px;
    height: 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background: url(/static/media/arrow.456705e5.svg) no-repeat center center/contain; }
  .job-screen__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: url(/static/media/bg.4b731fbc.jpg) center center/cover; }
  .job-screen .job-screen__title {
    margin: 0 -35px 30px; }
  .job-screen h2 {
    font-family: "proxima-bold";
    font-size: 18px; }
  .job-screen__title {
    margin-bottom: 30px;
    background: url(/static/media/bg.4b731fbc.jpg) center center/cover;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out; }
    .job-screen__title h1 {
      font-size: 22px;
      font-family: "montserrat";
      font-weight: 900;
      line-height: 33px;
      max-width: 280px;
      margin: 0 auto 10px; }
  .job-screen__job-info li {
    display: inline-block;
    font-size: 14px;
    margin: 0 12px; }
    .job-screen__job-info li::before {
      content: '';
      display: inline-block;
      position: relative;
      top: 3px;
      width: 16px;
      height: 16px;
      margin-right: 4px; }
    .job-screen__job-info li:nth-of-type(1)::before {
      background: url(/static/media/building.cfbb79e7.svg) no-repeat center center/contain; }
    .job-screen__job-info li:nth-of-type(2)::before {
      background: url(/static/media/pin.bab8d257.svg) no-repeat center center/contain; }
    .job-screen__job-info li:nth-of-type(3)::before {
      background: url(/static/media/clock.e63f4a69.svg) no-repeat center center/contain; }
  .job-screen .text-block {
    position: relative;
    white-space: pre-wrap;
    -webkit-transition: all .5s ease;
    transition: all .5s ease; }
    .job-screen .text-block .shaved {
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 1;
      -webkit-transition: .5s ease;
      transition: .5s ease; }
    .job-screen .text-block .condensed {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: 0;
      left: 0;
      z-index: -1; }
  .job-screen__description {
    text-align: left;
    margin-bottom: 25px; }
    .job-screen__description h2 {
      margin-bottom: 4px; }
    .job-screen__description p {
      line-height: 1.4; }
    .job-screen__description .show-more {
      padding: 5px 0;
      position: relative;
      z-index: 10; }
  .job-screen__benefits {
    text-align: left;
    margin-bottom: 40px; }
    .job-screen__benefits h2 {
      margin-bottom: 4px; }
    .job-screen__benefits li {
      position: relative;
      line-height: 1.4;
      margin-bottom: 10px;
      padding-left: 25px; }
      .job-screen__benefits li::before {
        content: '';
        width: 15px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 5px;
        background: url(/static/media/benefit.e7a09ea1.svg) no-repeat center center/contain; }
      .job-screen__benefits li:last-of-type {
        margin-bottom: 0; }
  .job-screen__apply-buttons .linkedin {
    background: #2F76B3; }
  .job-screen__apply-buttons .facebook {
    background: #415896; }
  .job-screen__apply-buttons .manual {
    font-size: 16px;
    font-family: "proxima";
    color: #9B9B9B;
    margin-top: 10px; }

.form-screen {
  overflow: hidden;
  color: #4a4a4a;
  background: #F9F9F9; }
  .form-screen .container {
    max-width: 100%; }
  .form-screen__button {
    background: #51C380;
    border-radius: 5px;
    margin: 0 auto;
    width: 100%;
    display: block; }
    .form-screen__button.form-screen__button--disabled {
      opacity: 0.2; }
  .form-screen__title {
    color: #fff;
    text-align: center;
    padding: 40px 0;
    margin-bottom: 20px; }
  .form-screen__progress {
    margin-bottom: 25px; }
    .form-screen__progress.step-1 li:nth-of-type(1) {
      background: #47B7B3;
      position: relative;
      border: none; }
      .form-screen__progress.step-1 li:nth-of-type(1)::before {
        content: ''; }
    .form-screen__progress.step-1 li:nth-of-type(2) {
      background: #57CA65;
      border: 6px solid #fff;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); }
    .form-screen__progress.step-2 ul::before {
      background: -webkit-gradient(linear, left top, right top, from(#47B7B3), to(#57CA65));
      background: -webkit-linear-gradient(left, #47B7B3 0%, #57CA65 100%);
      background: linear-gradient(to right, #47B7B3 0%, #57CA65 100%); }
    .form-screen__progress.step-2 li {
      background: #47B7B3;
      border-color: #47B7B3; }
      .form-screen__progress.step-2 li:nth-of-type(1) {
        position: relative;
        border: none; }
        .form-screen__progress.step-2 li:nth-of-type(1)::before {
          content: ''; }
      .form-screen__progress.step-2 li:nth-of-type(2) {
        position: relative;
        border: none; }
        .form-screen__progress.step-2 li:nth-of-type(2)::before {
          content: ''; }
      .form-screen__progress.step-2 li:nth-of-type(3) {
        background: #54C669;
        border: 6px solid #fff;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); }
    .form-screen__progress ul {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      position: relative; }
      .form-screen__progress ul::before {
        position: absolute;
        content: '';
        top: 9px;
        left: 0;
        height: 3px;
        width: 100%;
        background: -webkit-gradient(linear, left top, right top, from(#47B7B3), color-stop(50%, #57CA65), color-stop(50%, #d8d8d8), to(#d8d8d8));
        background: -webkit-linear-gradient(left, #47B7B3 0%, #57CA65 50%, #d8d8d8 50%, #d8d8d8 100%);
        background: linear-gradient(to right, #47B7B3 0%, #57CA65 50%, #d8d8d8 50%, #d8d8d8 100%); }
    .form-screen__progress li {
      display: block;
      width: 22px;
      height: 22px;
      background: #fff;
      border-radius: 50%;
      border: 3px solid #d8d8d8;
      position: relative;
      z-index: 3; }
      .form-screen__progress li::before {
        position: absolute;
        left: 7px;
        top: 4px;
        width: 5px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
  .form-screen__form-section {
    padding: 0 18px;
    max-width: 420px;
    margin: 0 auto; }
    .form-screen__form-section label {
      display: block;
      margin-bottom: 8px; }
      .form-screen__form-section label .label-text {
        display: inline-block;
        padding-left: 24px;
        -webkit-transition: .2s ease;
        transition: .2s ease; }
    .form-screen__form-section .screen {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0; }
      .form-screen__form-section .screen.second-screen {
        margin-bottom: 18px; }
      .form-screen__form-section .screen.active {
        position: relative;
        z-index: 3;
        opacity: 1;
        -webkit-transition: opacity .4s .3s;
        transition: opacity .4s .3s;
        -webkit-animation: form-items .5s ease;
                animation: form-items .5s ease; }
        .form-screen__form-section .screen.active .change-photo, .form-screen__form-section .screen.active .experience {
          -webkit-animation: form-items .5s ease;
                  animation: form-items .5s ease;
          -webkit-animation-delay: .15s;
                  animation-delay: .15s; }
      .form-screen__form-section .screen.first-screen {
        position: relative; }
        .form-screen__form-section .screen.first-screen .fill-in {
          margin-bottom: 18px; }
          .form-screen__form-section .screen.first-screen .fill-in .form-screen__block {
            margin: 0 auto 28px;
            padding: 0; }

@-webkit-keyframes form-items {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes form-items {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }
    .form-screen__form-section .change-photo {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center; }
      .form-screen__form-section .change-photo__image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 18px; }
        .form-screen__form-section .change-photo__image img {
          position: absolute;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          max-width: 140%;
          max-height: 140%;
          background: #DEE2E6; }
      .form-screen__form-section .change-photo .form-screen__change {
        font-size: 15px; }
        .form-screen__form-section .change-photo .form-screen__change span {
          display: block;
          color: #51C380;
          font-family: "proxima-bold"; }
        .form-screen__form-section .change-photo .form-screen__change button {
          font-family: "proxima";
          font-size: 15px;
          margin-top: 0; }
    .form-screen__form-section .apply-via label {
      position: relative;
      display: block;
      cursor: pointer; }
      .form-screen__form-section .apply-via label.checked {
        pointer-events: none; }
      .form-screen__form-section .apply-via label:last-child {
        margin-bottom: 0; }
      .form-screen__form-section .apply-via label img {
        max-width: 80px; }
    .form-screen__form-section .apply-via .radio {
      top: 0;
      right: 0;
      left: auto;
      width: 50px;
      height: 24px;
      opacity: 0;
      visibility: hidden; }
    .form-screen__form-section .apply-via .radiomark {
      width: 50px;
      height: 24px;
      border: 1px solid #DEE2E6;
      background: #DEE2E6;
      top: 1px;
      left: auto;
      right: 0;
      margin-right: 0;
      border-radius: 25px;
      z-index: 5;
      cursor: pointer; }
      .form-screen__form-section .apply-via .radiomark::before {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: 2px;
        top: 2px; }
    .form-screen__form-section .apply-via .radio:checked ~ .radiomark {
      border-color: #51C380;
      background: white;
      -webkit-transition: .2s .2s;
      transition: .2s .2s;
      pointer-events: none; }
      .form-screen__form-section .apply-via .radio:checked ~ .radiomark::before {
        left: 28px;
        -webkit-transition-duration: .2s;
                transition-duration: .2s; }
      .form-screen__form-section .apply-via .radio:checked ~ .radiomark::after {
        opacity: 1;
        -webkit-transition: .2s .2s;
        transition: .2s .2s; }
    .form-screen__form-section .apply-via .radiomark::after {
      width: 5px;
      height: 11px;
      border: solid #51C380;
      top: 2px;
      left: 50%;
      -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
              transform: translate3d(-50%, 0, 0) rotate(45deg);
      background: #fff;
      border-width: 0 2px 2px 0;
      border-radius: 0;
      content: '';
      opacity: 0; }
    .form-screen__form-section .apply-via h2 {
      font-size: 22px;
      font-family: "proxima";
      color: #4a4a4a;
      margin-bottom: 15px; }
    .form-screen__form-section .fill-in input[value=""] {
      position: relative;
      border-bottom: 1px solid #9b9b9b !important;
      background: #ffffff;
      z-index: 1; }
    .form-screen__form-section .fill-in .form-screen__block h2 {
      font-size: 16px;
      text-align: left;
      font-family: "proxima-bold";
      line-height: 1.35;
      margin-bottom: 10px; }
    .form-screen__form-section .fill-in .form-screen__block label {
      display: inline-block;
      margin-right: 20px; }
    .form-screen__form-section .fill-in h2 {
      font-size: 22px;
      font-family: "proxima";
      color: #4a4a4a;
      margin-bottom: 15px; }
    .form-screen__form-section .fill-in h3 {
      font-size: 16px;
      font-family: "proxima-bold";
      line-height: 1.35;
      margin-bottom: 10px; }
      .form-screen__form-section .fill-in h3.avatar {
        display: none; }
    .form-screen__form-section .fill-in .saved-data .form-screen__block label::after {
      display: none; }
    .form-screen__form-section .fill-in .saved-data input, .form-screen__form-section .fill-in .saved-data select {
      border-width: 2px;
      border-color: #51C380; }
    .form-screen__form-section .fill-in .saved-data input[type="date"]::before {
      display: none; }
    .form-screen__form-section .fill-in .saved-data label::after {
      position: absolute;
      content: '';
      display: block;
      right: 8px;
      bottom: 14px;
      width: 4px;
      height: 8px;
      border: solid #51C380;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .form-screen__form-section .fill-in label input, .form-screen__form-section .fill-in label select {
      -webkit-transition: .2s;
      transition: .2s; }
    .form-screen__form-section .fill-in label.validated {
      position: relative; }
      .form-screen__form-section .fill-in label.validated input, .form-screen__form-section .fill-in label.validated select {
        border-width: 2px;
        border-color: #51C380; }
      .form-screen__form-section .fill-in label.validated input[type=date]:before {
        content: '';
        margin-right: 0; }
      .form-screen__form-section .fill-in label.validated::after {
        position: absolute;
        content: '';
        display: block;
        right: 8px;
        bottom: 14px;
        width: 4px;
        height: 8px;
        border: solid #51C380;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
    .form-screen__form-section .fill-in label.avatar::after {
      display: none; }
    .form-screen__form-section .fill-in label.not-valid input {
      color: #E36171;
      border-color: #E36171; }
    .form-screen__form-section .fill-in label.not-valid::after {
      display: none; }
    .form-screen__form-section .fill-in .form-group {
      margin-bottom: 28px; }
      .form-screen__form-section .fill-in .form-group.CVField {
        margin-bottom: 40px; }
        .form-screen__form-section .fill-in .form-group.CVField .uploadedCV {
          margin-top: 20px; }
          .form-screen__form-section .fill-in .form-group.CVField .uploadedCV .hint {
            display: block;
            text-align: center;
            margin-top: 5px;
            font-size: 14px;
            color: #9B9B9B; }
          .form-screen__form-section .fill-in .form-group.CVField .uploadedCV .fileName {
            position: relative;
            display: block;
            background: #f9f9f9;
            border-radius: 5px;
            padding: 15px 50px 15px 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer; }
            .form-screen__form-section .fill-in .form-group.CVField .uploadedCV .fileName .checkIco {
              position: absolute;
              right: 15px;
              top: 50%;
              margin-top: -15px;
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 30px;
              height: 30px;
              background: #51C380;
              border-radius: 50%;
              margin-left: 14px;
              -webkit-transition: all .4s ease;
              transition: all .4s ease; }
              .form-screen__form-section .fill-in .form-group.CVField .uploadedCV .fileName .checkIco:before {
                content: '';
                margin: -4px 0 0 -1px;
                width: 6px;
                height: 11px;
                border: solid #fff;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transition: all .4s ease;
                transition: all .4s ease; }
        .form-screen__form-section .fill-in .form-group.CVField h3 {
          display: flex;
          justify-content: space-between; }
          .form-screen__form-section .fill-in .form-group.CVField h3 span {
            color: #9b9b9b;
            font-family: "proxima";
            font-size: 13px; }
        .form-screen__form-section .fill-in .form-group.CVField:after {
          display: none; }
        .form-screen__form-section .fill-in .form-group.CVField .callCV {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #F9F9F9;
          padding: 15px 10px;
          cursor: pointer;
          margin-top: 10px; }
          @media all and (min-width: 992px) {
            .form-screen__form-section .fill-in .form-group.CVField .callCV {
              padding: 15px 25px; } }
          .form-screen__form-section .fill-in .form-group.CVField .callCV h4 {
            width: 100%;
            font-size: 16px; }
          .form-screen__form-section .fill-in .form-group.CVField .callCV .social-icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%; }
            .form-screen__form-section .fill-in .form-group.CVField .callCV .social-icons img {
              max-width: 26px;
              margin-left: 10px; }
              .form-screen__form-section .fill-in .form-group.CVField .callCV .social-icons img:first-child {
                margin-left: 0; }
              @media all and (min-width: 992px) {
                .form-screen__form-section .fill-in .form-group.CVField .callCV .social-icons img {
                  margin-left: 15px; } }
    .form-screen__form-section .fill-in .form-error {
      font-size: 14px;
      color: #E36171; }
    .form-screen__form-section .fill-in input, .form-screen__form-section .fill-in select {
      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #9b9b9b;
      color: #9b9b9b;
      font-size: 16px;
      padding-bottom: 5px;
      font-family: "proxima";
      background: transparent; }
      .form-screen__form-section .fill-in input::-webkit-input-placeholder, .form-screen__form-section .fill-in select::-webkit-input-placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .fill-in input::-moz-placeholder, .form-screen__form-section .fill-in select::-moz-placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .fill-in input::-ms-input-placeholder, .form-screen__form-section .fill-in select::-ms-input-placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .fill-in input::placeholder, .form-screen__form-section .fill-in select::placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .fill-in input.validated, .form-screen__form-section .fill-in select.validated {
        font-family: "proxima-bold";
        color: #51C380;
        position: relative; }
        .form-screen__form-section .fill-in input.validated::after, .form-screen__form-section .fill-in select.validated::after {
          position: absolute;
          content: '';
          display: block;
          right: 8px;
          top: 0;
          width: 6px;
          height: 10px;
          border: solid #51C380;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
    .form-screen__form-section .textarea.not-valid textarea {
      color: #E36171;
      border-color: #E36171; }
    .form-screen__form-section .textarea.not-valid::after {
      display: none; }
    .form-screen__form-section .textarea textarea {
      width: 100%;
      display: block;
      border: none;
      border-bottom: 1px solid #9b9b9b;
      color: #9b9b9b;
      font-size: 16px;
      padding-bottom: 5px;
      margin-bottom: 25px;
      font-family: "proxima";
      box-sizing: border-box;
      -webkit-transition: .2s;
      transition: .2s;
      padding-right: 10px; }
      .form-screen__form-section .textarea textarea::-webkit-input-placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .textarea textarea::-moz-placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .textarea textarea::-ms-input-placeholder {
        font-size: 16px;
        color: #9b9b9b; }
      .form-screen__form-section .textarea textarea::placeholder {
        font-size: 16px;
        color: #9b9b9b; }
    .form-screen__form-section .second-screen button.alt::after {
      display: none; }
    .form-screen__form-section .second-screen h2 {
      font-size: 16px;
      font-family: "proxima-bold";
      line-height: 1.35;
      margin-bottom: 16px; }
  .form-screen__block {
    display: block;
    width: 100%;
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px; }
  .form-screen__tos {
    display: block;
    padding: 0;
    font-family: 'proxima';
    margin: 10px auto 0;
    color: #9B9B9B;
    font-size: 14px;
    text-align: center;
    width: 300px; }

.applied-screen {
  background: -webkit-linear-gradient(45deg, #4CBC9D 0%, #57CA65 100%);
  background: linear-gradient(45deg, #4CBC9D 0%, #57CA65 100%);
  text-align: center;
  padding: 20px 16px 30px;
  min-height: 100vh; }
  .applied-screen h2 {
    color: #4a4a4a;
    font-size: 22px;
    font-family: "proxima-semib"; }
  .applied-screen span {
    display: block;
    font-size: 16px;
    color: #9B9B9B; }
  .applied-screen .percentage {
    color: #51C380;
    font-family: "proxima-bold"; }
  .applied-screen .job-screen__logo {
    display: none; }
  .applied-screen__title {
    margin-bottom: 65px;
    background: none !important; }
    .applied-screen__title span {
      font-family: "proxima-bold";
      font-size: 32px;
      color: #fff; }
      .applied-screen__title span::after {
        content: '';
        position: relative;
        display: block;
        height: 3px;
        width: 120px;
        margin: 13px auto 22px;
        background: #ececec; }
  .applied-screen .confirmation-animated-header {
    -webkit-animation: confirmation-header .3s forwards;
            animation: confirmation-header .3s forwards; }

@-webkit-keyframes confirmation-header {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes confirmation-header {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.profile__info {
  position: relative;
  margin-top: 40px;
  padding: 1px;
  -webkit-animation: bounceInDown 1s;
          animation: bounceInDown 1s; }
  .profile__info::before {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    top: 0;
    height: 75%;
    z-index: 1;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.3); }
  .profile__info-wrapper {
    position: relative;
    padding: 0 20px 30px;
    background: url(/static/media/applied-bg1.ca628084.svg) bottom center/cover;
    z-index: 1;
    min-height: 210px; }
  .profile__info h2 {
    line-height: 1.1;
    padding-top: 12px; }
  .profile__info .percentage {
    font-size: 32px;
    padding-top: 20px;
    line-height: 1; }
    .profile__info .percentage::before {
      content: '';
      display: inline-block;
      width: 20px;
      margin-right: 5px;
      height: 22px;
      position: relative;
      top: 1px;
      background: url(/static/media/percentage.895671d4.svg) no-repeat center center/contain; }
  .profile__info .photo {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    cursor: pointer; }
    .profile__info .photo.withPhoto .plus {
      display: none; }
    .profile__info .photo .plus {
      position: absolute;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #51C380;
      border-radius: 50%;
      font-size: 24px;
      color: #ffffff;
      right: 0;
      bottom: 0; }
      .profile__info .photo .plus:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 2px;
        background: #ffffff;
        left: 50%;
        top: 50%;
        margin: -7px 0 0 -1px;
        border-radius: 1px; }
      .profile__info .photo .plus:after {
        position: absolute;
        content: '';
        width: 14px;
        height: 2px;
        background: #ffffff;
        left: 50%;
        top: 50%;
        margin: -1px 0 0 -7px;
        border-radius: 1px; }
    .profile__info .photo__container {
      position: relative;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .profile__info .photo img {
      border-radius: 50%;
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      max-width: 140%;
      max-height: 140%;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .profile__info span {
    line-height: 1.6; }

.profile__improve {
  margin-top: -8px;
  text-align: left;
  position: relative;
  -webkit-animation: bounceInUp 1s;
          animation: bounceInUp 1s; }
  .profile__improve::before {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    height: 82%;
    z-index: 1;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.3); }
  .profile__improve-wrapper {
    position: relative;
    z-index: 3;
    background: url(/static/media/applied-bg2.93057e2c.svg) no-repeat top center/cover;
    padding: 42px 20px 20px; }
    .profile__improve-wrapper ul {
      width: 290px;
      margin: 0 auto;
      max-width: 100%; }
  .profile__improve h2 {
    text-align: center;
    margin-bottom: 16px; }
  .profile__improve li {
    margin-bottom: 24px; }
    .profile__improve li:last-child {
      margin-bottom: 6px; }
    .profile__improve li a {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      position: relative;
      outline: none; }
    .profile__improve li h3 {
      font-size: 16px;
      line-height: 1.4;
      color: #000; }
    .profile__improve li .upload {
      width: 26px;
      height: 26px;
      display: block;
      position: absolute;
      right: 8px;
      top: 2px;
      background: url(/static/media/upload.c9c00c38.svg) no-repeat center center/cover;
      -webkit-transition: all .4s ease;
      transition: all .4s ease; }
  .profile__improve .completed .upload {
    opacity: 0;
    visibility: hidden; }
  .profile__improve .completed .percentage {
    font-size: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border: 3px solid #51C380;
    background: #51C380;
    border-radius: 50%;
    margin-left: 13px;
    margin-right: 28px; }
    .profile__improve .completed .percentage:before {
      opacity: 1; }
  .profile__improve .percentage {
    font-size: 22px;
    margin-right: 18px;
    line-height: 1.3;
    min-width: 30px;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease; }
    .profile__improve .percentage:before {
      content: '';
      margin: -4px 0 0 -1px;
      width: 6px;
      height: 11px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
  .profile__improve img {
    width: 48px;
    height: 48px;
    border-radius: 50%; }
  .profile__improve-contact {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 290px;
    margin: 0 auto;
    max-width: 100%; }
    .profile__improve-contact img {
      margin-right: 23px; }
    .profile__improve-contact a {
      display: block;
      color: #9B9B9B; }

@-webkit-keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@-webkit-keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 0;
  margin-left: -50%;
  padding: 30px 18px;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  z-index: -1;
  text-align: left;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }
  .popup__profile-text.selected {
    display: none; }
  .popup__origin-text, .popup span {
    display: block;
    font-size: 15px;
    color: #9b9b9b;
    font-family: "proxima";
    margin-top: 5px; }
  .popup-cv .popup__content {
    margin-top: 100%; }
  .popup-cv__error .popup__title {
    color: #DB576D;
    margin-bottom: 20px; }
  .popup-cv__error .error__text {
    font-size: 14px;
    color: #8E8E8E; }
  .popup-cv__error .popup__tab-button {
    font-size: 16px; }
  .popup.active {
    opacity: 1;
    visibility: visible;
    top: 0;
    z-index: 1000; }
    .popup.active .popup__content {
      margin-top: 0;
      opacity: 1;
      z-index: 5; }
  .popup__overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6); }
  .popup__close {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto 15px;
    padding: 0;
    border: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
    .popup__close:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      background: #ffffff;
      left: 0;
      top: 50%;
      margin-top: -1px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .popup__close:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      background: #ffffff;
      left: 0;
      top: 50%;
      margin-top: -1px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .popup__content {
    position: relative;
    background: #F9F9F9;
    padding: 20px 20px 26px;
    border-radius: 5px;
    overflow: auto;
    z-index: 5;
    max-width: 420px;
    margin: 60px auto 0;
    opacity: 0;
    -webkit-transition: margin-top .2s ease, opacity .4s ease;
    transition: margin-top .2s ease, opacity .4s ease; }
  .popup__title {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #4A4A4A;
    margin-bottom: 20px; }
  .popup label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px 10px 16px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 10px; }
    .popup label.popup__no-photo {
      padding-left: 20px; }
    .popup label .popup__profile-text {
      margin-left: 16px; }
    .popup label input[type=radio] {
      display: none; }
      .popup label input[type=radio]:checked ~ p {
        color: #51C380;
        font-family: 'proxima-bold'; }
      .popup label input[type=radio]:checked ~ .popup__profile-text {
        display: none; }
        .popup label input[type=radio]:checked ~ .popup__profile-text.selected {
          display: block; }
      .popup label input[type=radio]:checked ~ .popup__checked-status {
        background: #51C380;
        border-color: #51C380; }
        .popup label input[type=radio]:checked ~ .popup__checked-status:before {
          opacity: 1; }
    .popup label p {
      -webkit-transition: all .4s ease;
      transition: all .4s ease; }
    .popup label .popup__checked-status {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30px;
      height: 30px;
      margin-top: 0;
      border: 3px solid #DDDDDD;
      border-radius: 50%;
      margin-left: auto;
      -webkit-transition: all .4s ease;
      transition: all .4s ease; }
      .popup label .popup__checked-status:before {
        content: '';
        margin: -4px 0 0 -1px;
        width: 6px;
        height: 11px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        -webkit-transition: all .4s ease;
        transition: all .4s ease; }
  .popup p {
    font-size: 16px;
    line-height: 18px;
    color: #4A4A4A; }
  .popup__profile {
    cursor: pointer; }
    .popup__profile-text {
      margin: 0 5px; }
    .popup__profile .popup__profile-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      min-width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 0; }
      .popup__profile .popup__profile-image img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: 140%;
        max-height: 140%;
        background: #DEE2E6; }
  .popup__no-photo {
    cursor: pointer; }
    .popup__no-photo-text span {
      display: block;
      font-size: 15px;
      color: #9b9b9b; }
  .popup__upload-text {
    width: 100%; }
  .popup__upload-socials {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    padding: 16px 27px 16px 20px; }
  .popup__upload-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%; }
    .popup__upload-list .popup__upload-icon {
      margin-left: 10px;
      max-width: 30px;
      width: calc(25% - 5px); }
  .popup__upload .popup__upload-item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    border-radius: 5px;
    padding: 16px 27px 16px 20px;
    cursor: pointer; }
    .popup__upload .popup__upload-item:after {
      display: none !important; }
    .popup__upload .popup__upload-item.social {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: -50px;
      -webkit-transition: all .4s ease;
      transition: all .4s ease; }
      .popup__upload .popup__upload-item.social-li {
        -webkit-transition-delay: .4s;
                transition-delay: .4s; }
      .popup__upload .popup__upload-item.social-fb {
        -webkit-transition-delay: .3s;
                transition-delay: .3s; }
      .popup__upload .popup__upload-item.social-db {
        -webkit-transition-delay: .2s;
                transition-delay: .2s; }
      .popup__upload .popup__upload-item.social-gd {
        -webkit-transition-delay: .1s;
                transition-delay: .1s; }
    .popup__upload .popup__upload-item .popup__upload-icon {
      width: 30px;
      margin-right: 24px; }
  .popup__upload.show .popup__upload-socials {
    display: none; }
  .popup__upload.show .popup__upload-item.social {
    position: relative;
    visibility: visible;
    opacity: 1;
    top: 0; }
  .popup__fb {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
    .popup__fb-status {
      position: absolute;
      width: 30px;
      height: 30px;
      border: 3px solid #DDDDDD;
      border-radius: 50%;
      top: 10px;
      right: 10px;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
      .popup__fb-status:before {
        position: absolute;
        content: '';
        width: 5px;
        height: 11px;
        border: solid #fff;
        top: 3px;
        left: 50%;
        -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
                transform: translate3d(-50%, 0, 0) rotate(45deg);
        background: #51C380;
        border-width: 0 3px 3px 0;
        border-radius: 0;
        opacity: 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
    .popup__fb label {
      position: relative;
      width: calc(50% - 10px);
      text-align: center;
      margin-top: 10px;
      padding: 0;
      cursor: pointer; }
      .popup__fb label input:checked ~ .popup__fb-status {
        background: #51C380;
        border-color: #51C380; }
        .popup__fb label input:checked ~ .popup__fb-status:before {
          opacity: 1; }
      .popup__fb label img {
        border-radius: 6px;
        max-width: 100%; }
  .popup__tab {
    display: none; }
    .popup__tab-title {
      width: 100%;
      text-align: center; }
    .popup__tab-button {
      margin: 40px 0 0;
      width: auto;
      text-align: center;
      line-height: 18px;
      font-family: "proxima-bold";
      color: #51C380;
      padding: 0; }
    .popup__tab-text {
      font-size: 14px;
      color: #8E8E8E;
      width: 100%;
      text-align: center;
      margin-top: 10px; }
    .popup__tab.active {
      display: block; }
      .popup__tab.active.popup__fb {
        display: flex; }
  .popup__controlsContainer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between; }
  .popup__cancel {
    color: #BCBCBC;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px; }
  .popup__confirm {
    color: #FFFFFF;
    background-color: #51C380;
    border-radius: 5px;
    margin-bottom: 0; }
  .popup-terms {
    text-align: center;
    padding: 0;
    overflow: hidden; }
    .popup-terms .popup__content {
      position: relative;
      padding: 0 0 30px;
      max-height: 100%;
      height: 100%;
      max-width: 100%;
      margin: 0 auto !important;
      border-radius: 0; }
    .popup-terms .popup__header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding: 40px 20px 25px;
      background: #F9F9F9; }
    .popup-terms .title {
      margin-top: 15px;
      font-family: 'Montserrat';
      font-size: 22px;
      line-height: 33px;
      color: #4A4A4A; }
    .popup-terms__close {
      display: inline-block;
      font-size: 18px;
      line-height: 22px;
      color: #BCBCBC;
      padding: 0;
      margin: 0;
      opacity: 1; }
    .popup-terms hr {
      display: block;
      margin: 24px auto 0;
      width: 117px;
      border-top: 3px solid #BCBCBC; }
    .popup-terms__text {
      padding: 5px 20px;
      margin: 0 auto;
      width: 340px;
      max-width: 100%;
      text-align: left; }
      .popup-terms__text p {
        font-size: 18px;
        line-height: 25px;
        color: #2C2C2C;
        margin: 10px 0; }
      .popup-terms__text strong {
        font-family: 'proxima-bold'; }
    .popup-terms__agree {
      display: block;
      opacity: 1;
      margin: 30px auto 0;
      padding: 0;
      color: #51C380;
      font-family: "proxima-bold"; }

.error-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  background: #202020; }
  .error-block__text {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding-right: 30px; }
    .error-block__text span {
      color: #818181; }
  .error-block__retry {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #51C380; }

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  min-height: 100vh; }
  .notFound-title {
    font-family: "montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 33px;
    color: #51C380; }
  .notFound-heading {
    line-height: 24px;
    color: #4A4A4A;
    font-size: 22px;
    margin-top: 20px; }
  .notFound-text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #8E8E8E; }
  .notFound-button {
    display: block;
    margin: 40px auto 0;
    background: #51C380;
    border-radius: 30px;
    width: 180px;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 14px 0;
    cursor: pointer;
    font-weight: 700; }
  .notFound-link {
    margin-top: 20px;
    font-size: 16px;
    line-height: 18px;
    color: #51C380; }

.spinner {
  height: 80px;
  width: 80px;
  -webkit-animation: spinner-1-1 3.8s linear infinite;
          animation: spinner-1-1 3.8s linear infinite;
  background: #F2F2F2;
  border-radius: 50%;
  margin: 0 auto; }
  .spinner::after {
    content: "";
    position: absolute;
    height: 64px;
    width: 64px;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .spinner span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 80px;
    width: 80px;
    clip: rect(0, 80px, 80px, 40px);
    -webkit-animation: spinner-1-2 1.2s linear infinite;
    animation: spinner-1-2 1.2s linear infinite; }
    .spinner span::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 80px;
      width: 80px;
      clip: rect(0, 80px, 80px, 40px);
      border: 8px solid #57CA65;
      border-radius: 50%;
      -webkit-animation: spinner-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      animation: spinner-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite; }

@-webkit-keyframes spinner-1-1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-1-1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes spinner-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(220deg);
            transform: rotate(220deg); } }

@keyframes spinner-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(220deg);
            transform: rotate(220deg); } }

@-webkit-keyframes spinner-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
            transform: rotate(-140deg); }
  50% {
    -webkit-transform: rotate(-160deg);
            transform: rotate(-160deg); }
  100% {
    -webkit-transform: rotate(140deg);
            transform: rotate(140deg); } }

@keyframes spinner-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
            transform: rotate(-140deg); }
  50% {
    -webkit-transform: rotate(-160deg);
            transform: rotate(-160deg); }
  100% {
    -webkit-transform: rotate(140deg);
            transform: rotate(140deg); } }

.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .loading *, .loading ::after, .loading ::before {
    box-sizing: border-box; }
  .loading__title {
    color: #9B9B9B;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 50px; }
  .loading__container {
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }

@media all and (min-width: 992px) {
  .container {
    min-height: 100vh; }
  .job-screen {
    padding: 0; }
    .job-screen__logo {
      margin-bottom: 40px; }
    .job-screen:before {
      width: 31.25%; }
    .job-screen.revealed {
      padding-top: 0; }
    .job-screen h2 {
      font-size: 20px; }
    .job-screen .job-screen__title {
      margin: 0 0 30px; }
    .job-screen__background {
      display: none; }
    .job-screen__description h2 {
      margin-bottom: 20px; }
    .job-screen .container {
      max-width: 100%; }
    .job-screen .content {
      display: flex;
      margin-left: 31.25%; }
    .job-screen__title {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 31.25%;
      height: 100vh;
      padding: 15px 3px 35px;
      margin: 0;
      -webkit-transition: none;
      transition: none;
      left: 0;
      top: 0; }
    .job-screen__content {
      padding: 80px 100px 40px;
      color: #202020;
      max-width: 100%; }
    .job-screen__benefits {
      margin-bottom: 90px; }
    .job-screen__apply-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .job-screen__apply-buttons li {
        margin: 0 10px; }
        .job-screen__apply-buttons li:last-child {
          flex: 0 0 100%; }
  .form-screen {
    padding: 0; }
    .form-screen .popup-cv {
      width: 68.75%; }
    .form-screen__button {
      width: 330px; }
    .form-screen__progress {
      margin-bottom: 40px; }
    .form-screen .container {
      display: flex;
      max-width: 100%; }
    .form-screen__form-section {
      background: #F9F9F9;
      padding: 40px 60px;
      max-width: none;
      max-width: initial;
      width: 68.75%;
      margin-left: 31.25%; }
      .form-screen__form-section .fill-in {
        padding: 30px 20px; }
        .form-screen__form-section .fill-in h2 {
          text-align: center; }
        .form-screen__form-section .fill-in .form-group {
          max-width: 330px;
          margin: 0 auto 28px; }
      .form-screen__form-section .change-photo .form-screen__change {
        width: calc(100% - 80px); }
      .form-screen__form-section .second-screen .form-screen__button {
        margin-top: 40px; }
      .form-screen__form-section .textarea textarea {
        margin-bottom: 10px; }
      .form-screen__form-section .screen.first-screen .fill-in .form-screen__block {
        max-width: 330px; }
    .form-screen__block {
      margin-bottom: 15px; }
      .form-screen__block.change-photo {
        padding: 20px 30px;
        cursor: pointer; }
      .form-screen__block.apply-via {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 40px; }
    .form-screen__blocks-group {
      display: flex;
      justify-content: space-between; }
      .form-screen__blocks-group .form-screen__block {
        width: calc(50% - 8px); }
  .applied-screen {
    padding: 25px 15px 47px; }
    .applied-screen .container {
      max-width: 520px;
      min-height: 0;
      min-height: initial; }
    .applied-screen .job-screen__title {
      min-height: 0;
      min-height: initial;
      height: auto;
      width: auto;
      padding-bottom: 30px;
      position: relative; }
  .profile__improve-wrapper {
    padding: 55px 115px 15px; }
  .popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 68.75%;
    right: 0;
    left: unset; }
    .popup-terms .popup__content {
      width: 100%; }
    .popup-terms__text {
      width: 520px; }
    .popup__content {
      max-width: 100%;
      width: 652px; }
    .popup__title {
      margin-bottom: 30px; }
    .popup__upload {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .popup__upload-socials {
        display: none; }
      .popup__upload div {
        width: calc(50% - 5px); }
        .popup__upload div > .popup__upload-item {
          width: 100%; }
      .popup__upload .popup__upload-item {
        width: calc(50% - 5px); }
        .popup__upload .popup__upload-item.social {
          position: relative;
          opacity: 1;
          visibility: visible;
          top: 0; }
    .popup label.popup__no-photo {
      margin-top: 40px;
      padding: 16px 20px; }
    .popup__variants {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 30px; }
    .popup__profile {
      width: calc(50% - 5px); }
    .popup__fb {
      justify-content: flex-start;
      margin: 0 -5px; }
      .popup__fb label {
        width: 145px;
        margin: 10px 5px 0; }
    .popup-cv {
      width: 100%;
      justify-content: flex-start;
      padding-top: 100px; }
      .popup-cv .popup__content {
        max-width: 340px;
        padding: 40px 20px; }
      .popup-cv .popup__upload > div, .popup-cv .popup__upload .popup__upload-item {
        width: 100%; }
    .popup-confirmation {
      width: 100%;
      justify-content: flex-start;
      padding-top: 100px;
      text-align: left; }
      .popup-confirmation span {
        font-size: 15px;
        display: flex; }
  .error-block {
    width: 68.75%;
    margin-left: 31.25%;
    padding: 20px 60px; }
    .error-block.thanks, .error-block.full-width {
      width: 100%;
      margin-left: 0; } }

@media all and (max-width: 991px) {
  .job-screen .job-screen__title {
    background: none !important; }
  .form-screen .job-screen__logo {
    display: none; } }

